import React, { useState } from "react";
import axios from "axios";
import "../styles/CalculoBalancete.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const CalculoBalancete = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [totalEntradasMes, setTotalEntradasMes] = useState(0);
  const [totalSaidasMes, setTotalSaidasMes] = useState(0);
  const [totalMes, setTotalMes] = useState(0);
  const [totalEntradasAno, setTotalEntradasAno] = useState(0);
  const [totalSaidasAno, setTotalSaidasAno] = useState(0);
  const [totalAno, setTotalAno] = useState(0);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const handleCalculations = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/balancete/calculo/${year}/${month}`
      );

      setTotalEntradasMes(data.totalEntradasMes);
      setTotalSaidasMes(data.totalSaidasMes);
      setTotalMes(data.totalMes);
      setTotalEntradasAno(data.totalEntradasAno);
      setTotalSaidasAno(data.totalSaidasAno);
      setTotalAno(data.totalAno);
    } catch (error) {
      console.error("Erro ao calcular balancete:", error);
    }
  };

  return (
    <div className="calculo-balancete-container">
      <h1>Cálculo do Balancete</h1>
      <div className="calculo-input-container">
        <label>Ano:</label>
        <input
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          required
        />
      </div>
      <div className="calculo-input-container">
        <label>Mês:</label>
        <input
          type="number"
          min="1"
          max="12"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          required
        />
      </div>
      <button onClick={handleCalculations}>Calcular</button>

      <table className="results-table">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Valor (€)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <FontAwesomeIcon icon={faPlus} style={{ color: "green" }} /> Montante Total de Entradas do Mês
            </td>
            <td>{totalEntradasMes} €</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon icon={faMinus} style={{ color: "red" }} /> Montante Total de Saídas do Mês
            </td>
            <td>{totalSaidasMes} €</td>
          </tr>
          <tr>
            <td>Montante Total do Mês</td>
            <td>{totalMes} €</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon icon={faPlus} style={{ color: "green" }} /> Montante Total de Entradas do Ano
            </td>
            <td>{totalEntradasAno} €</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon icon={faMinus} style={{ color: "red" }} /> Montante Total de Saídas do Ano
            </td>
            <td>{totalSaidasAno} €</td>
          </tr>
          <tr>
            <td>Montante Total do Ano</td>
            <td>{totalAno} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CalculoBalancete;
