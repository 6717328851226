// src/components/About.js
import React from 'react';
import '../styles/About.css'; // Importe o arquivo CSS

const About = () => {
    return (
        <div className="about-container"> {/* Adicione a classe aqui */}
            <h1 style={{ animationDelay: '0.3s' }}>Sobre Nós</h1>
            <p style={{ animationDelay: '0.4s' }}>A nossa associação humanitária foi fundada com a missão de ajudar as comunidades mais vulneráveis e necessitadas. Desde a sua criação, temos trabalhado incansavelmente para proporcionar apoio e assistência a indivíduos e famílias que enfrentam desafios significativos em suas vidas.</p>
            
            <h2 style={{ animationDelay: '0.5s' }}>Nossa Missão</h2>
            <p style={{ animationDelay: '0.6s' }}>Acreditamos que toda pessoa merece dignidade, respeito e a oportunidade de prosperar. A nossa missão é fornecer recursos, serviços e apoio que ajudem a criar um ambiente mais justo e equitativo para todos. Trabalhamos em diversas áreas, incluindo:</p>
            <ul>
                <li style={{ animationDelay: '0.7s' }}><strong>Apoio à Alimentação:</strong> Distribuímos alimentos a famílias em situação de insegurança alimentar, garantindo que ninguém passe fome.</li>
                <li style={{ animationDelay: '0.8s' }}><strong>Educação e Capacitação:</strong> Oferecemos programas de educação e capacitação que ajudam os indivíduos a adquirir novas habilidades e melhorar suas oportunidades de emprego.</li>
                <li style={{ animationDelay: '0.9s' }}><strong>Saúde e Bem-Estar:</strong> Realizamos campanhas de saúde e fornecemos serviços de apoio psicológico para promover o bem-estar mental e físico.</li>
                <li style={{ animationDelay: '1s' }}><strong>Apoio a Refugiados e Imigrantes:</strong> Trabalhamos para acolher e integrar refugiados e imigrantes, proporcionando assistência em sua adaptação a uma nova vida.</li>
            </ul>
            <h2 style={{ animationDelay: '1.1s' }}>Nossos Valores</h2>
            <p style={{ animationDelay: '1.2s' }}>Nós nos baseamos em valores fundamentais que orientam todas as nossas ações:</p>
            <ul>
                <li style={{ animationDelay: '1.3s' }}><strong>Empatia:</strong> Compreendemos as dificuldades enfrentadas pelas comunidades e buscamos ajudar de forma sensível e respeitosa.</li>
                <li style={{ animationDelay: '1.4s' }}><strong>Transparência:</strong> Operamos com total transparência em nossas ações e na utilização dos recursos, garantindo que nossos apoiadores saibam como suas contribuições estão sendo usadas.</li>
                <li style={{ animationDelay: '1.5s' }}><strong>Colaboração:</strong> Acreditamos que, juntos, podemos fazer uma diferença maior. Trabalhamos em parceria com outras organizações, governos e voluntários para maximizar nosso impacto.</li>
            </ul>

            <h2 style={{ animationDelay: '1.6s' }}>Como Você Pode Ajudar</h2>
            <p style={{ animationDelay: '1.7s' }}>Existem várias maneiras de se envolver com nossa associação, seja através de doações, voluntariado ou simplesmente espalhando a palavra sobre o nosso trabalho. Juntos, podemos construir um futuro melhor e mais solidário para todos.</p>
        </div>
    );
};

export default About;
