import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/EnrollForm.css"; // Add your styles here

const EnrollForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [newConsent, setNewConsent] = useState({
    ConsentimentoImagem: 0,
    ConsentimentoEducativo: 0,
    ConsentimentoDados: 0,
  });
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  const [newItem, setNewItem] = useState({
    Nome: "",
    NIF: 0,
    NumeroUtente: 0,
    Telefone: 0,
    Endereco: "",
    ContactoEmergencia: 0,
    AulaId: id,
    ConsentimentoInformado: 0,
    Presenca: 0,
    NomeEncEdu: "",
    ProfissaoEncEdu: "",
    EmailEncEdu: "",
    TelEncEdu: 0,
    EscolaridadeEncEdu: "",
    GrauParentescoEncEdu: "",
    Escola: "",
    DoencasCronicas: 0,
    ConhecimentoPrograma: "",
    IdParaConsentimentos: newConsent.id,
  });
  const [errors, setErrors] = useState({
    NIF: "O NIF Tem de ter 9 digitos!",
    Telefone: "Os Numeros de Telefone devem de conter pelo menos 9 digitos",
    EmailEncEdu: "Os emails devem ter um formato de email válido! exempo@exemplo.pt",
  }); // State to hold error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop submission if there are errors
    }

    try {
      // Create consent first
      const consentResponse = await axios.post(
        `${API_BASE_URL}/consentimentos`,
        {
          ConsentimentoDados: newConsent.ConsentimentoDados,
          ConsentimentoImagem: newConsent.ConsentimentoImagem,
          ConsentimentoEducacao: newConsent.ConsentimentoEducacao,
        }
      );
      setNewConsent(consentResponse);

      // Now create aluno with the consent ID
      await axios.post(`${API_BASE_URL}/alunos`, {
        ...newItem,
        IdParaConsentimentos: consentResponse.data.id,
      });
      console.log("Inscrição enviada:", newItem);
      navigate("/"); // Navigate back to home after successful submission
    } catch (error) {
      console.error("Erro ao inscrever", error);
    }
  };

  const handleConsentChange = (type, value) => {
    setNewConsent((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const validateForm = () => {
    const { Nome, NIF, Telefone, EmailEncEdu } = newItem;
    const validationErrors = {};
    const isNifValid = /^\d{9}$/.test(NIF);
    const isTelefoneValid = /^\d{9,}$/.test(Telefone);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(EmailEncEdu);
    const isAllFieldsFilled = Nome && NIF && Telefone && EmailEncEdu;

    if (!isAllFieldsFilled) {
      validationErrors.allFields = "Todos os campos são obrigatórios.";
    }
    if (!isNifValid) {
      validationErrors.NIF = "O NIF deve ter exatamente 9 dígitos.";
    }
    if (!isTelefoneValid) {
      validationErrors.Telefone = "O Telefone deve ter pelo menos 9 dígitos.";
    }
    if (!isEmailValid) {
      validationErrors.EmailEncEdu = "O email não é válido.";
    }

    return validationErrors;
  };
  return (
    <div className="form-container">
      <h2>Inscrição em Aula</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          value={newItem.Nome || ""}
          onChange={(e) => setNewItem({ ...newItem, Nome: e.target.value })}
          className={`form-input ${
            errors.allFields || errors.Nome ? "invalid" : ""
          }`}
        />
        {errors.Nome && <p className="error-message">{errors.Nome}</p>}

        <input
          type="number"
          placeholder="NIF"
          value={newItem.NIF || ""}
          onChange={(e) => setNewItem({ ...newItem, NIF: e.target.value })}
          className={`form-input ${errors.NIF ? "invalid" : ""}`}
        />
        {errors.NIF && <p className="error-message">{errors.NIF}</p>}

        <input
          type="text"
          placeholder="Número de Utente"
          value={newItem.NumeroUtente || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, NumeroUtente: e.target.value })
          }
          className="form-input"
        />

        <input
          type="text"
          placeholder="Telefone"
          value={newItem.Telefone || ""}
          onChange={(e) => setNewItem({ ...newItem, Telefone: e.target.value })}
          className={`form-input ${errors.Telefone ? "invalid" : ""}`}
        />
        {errors.Telefone && <p className="error-message">{errors.Telefone}</p>}

        <input
          type="text"
          placeholder="Endereço"
          value={newItem.Endereco|| ""}
          onChange={(e) => setNewItem({ ...newItem, Endereco: e.target.value })}
          className="form-input"
        />

        <input
          type="text"
          placeholder="Contato de Emergência"
          value={newItem.ContactoEmergencia|| ""}
          onChange={(e) =>
            setNewItem({ ...newItem, ContactoEmergencia: e.target.value })
          }
          className="form-input"
        />
        <input
          type="text"
          placeholder="Escola que Frequenta"
          value={newItem.Escola || ""}
          onChange={(e) => setNewItem({ ...newItem, Escola: e.target.value })}
          className="form-input"
        />
        <input
          type="text"
          placeholder="Nome do Encarregado"
          value={newItem.NomeEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, NomeEncEdu: e.target.value })
          }
          className="form-input"
        />

        <input
          type="text"
          placeholder="Profissão do Encarregado"
          value={newItem.ProfissaoEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, ProfissaoEncEdu: e.target.value })
          }
          className="form-input"
        />

        <input
          type="text"
          placeholder="Email do Encarregado"
          value={newItem.EmailEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, EmailEncEdu: e.target.value })
          }
          className={`form-input ${errors.EmailEncEdu ? "invalid" : ""}`}
        />
        {errors.EmailEncEdu && (
          <p className="error-message">{errors.EmailEncEdu}</p>
        )}

        <input
          type="text"
          placeholder="Telefone do Encarregado"
          value={newItem.TelEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, TelEncEdu: e.target.value })
          }
          className="form-input"
        />

        <p>Qual a escolaridade do Encarregado de Educação?</p>
        <select
          id="como-conheceu"
          value={newItem.EscolaridadeEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, EscolaridadeEncEdu: e.target.value })
          }
          className="form-input"
        >
          <option value="" disabled>
            Escolaridade do Encarregado de Educação
          </option>
          <option value="Ensino Médio">Ensino Médio (5º Ano até 9ºAno)</option>
          <option value="Ensino Médio Incompleto">
            Ensino Médio Incompleto (5º Ano até 9ºAno)
          </option>
          <option value="Ensino Secundário">
            Ensino Secundário (10º Ano até 12ºAno)
          </option>
          <option value="Ensino Superior">
            Ensino Superior (Lecenciatura até Doutoramento)
          </option>
        </select>

        <p>Como conheceu o programa?</p>
        <select
          id="como-conheceu"
          value={newItem.ConhecimentoPrograma || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, ConhecimentoPrograma: e.target.value })
          }
          className="form-input"
        >
          <option value="" disabled>
            Como Conheceu o Programa?
          </option>
          <option value="Instagram">Instagram</option>
          <option value="Facebook">Facebook</option>
          <option value="Internet">Internet</option>
          <option value="Amigo">Amigo</option>
          <option value="Familiar">Familiar</option>
          <option value="Familiar">Já Frenquento Outros</option>
        </select>
        <p>Grau Parentesco:</p>
        <select
          id="grau-parentesco"
          value={newItem.GrauParentescoEncEdu || ""}
          onChange={(e) =>
            setNewItem({ ...newItem, GrauParentescoEncEdu: e.target.value })
          }
          className="form-input"
        >
          <option value="" disabled>
            Grau Parentesco
          </option>
          <option value="Mãe">Mãe</option>
          <option value="Pai">Pai</option>
          <option value="Irmão">Irmão</option>
          <option value="Irmã">Irmã</option>
          <option value="Avô">Avô</option>
          <option value="Avó">Avó</option>
          <option value="Tio">Tio</option>
          <option value="Tia">Tia</option>
          <option value="Primo/a">Primo/a</option>
        </select>
        <p>Doenças Crónicas</p>
        <div className="form-input">
          <label>
            <input
              type="radio"
              name="doencasCronicas"
              value="1"
              checked={newItem.DoencasCronicas === 1}
              onChange={() => setNewItem({ ...newItem, DoencasCronicas: 1 })}
            />{" "}
            Sim
          </label>
          <label>
            <input
              type="radio"
              name="doencasCronicas"
              value="0"
              checked={newItem.DoencasCronicas === 0}
              onChange={() => setNewItem({ ...newItem, DoencasCronicas: 0 })}
            />{" "}
            Não
          </label>
        </div>
        <p>Leu o consentimento informado da aula ? </p>
        <div className="form-input">
          <label>
            <input
              type="radio"
              name="consentimentoinfo"
              value="1"
              checked={newItem.ConsentimentoInformado === 1}
              onChange={() =>
                setNewItem({ ...newItem, ConsentimentoInformado: 1 })
              }
            />{" "}
            Sim
          </label>
          <label>
            <input
              type="radio"
              name="consentimentoinfo"
              value="0"
              checked={newItem.ConsentimentoInformado === 0}
              onChange={() =>
                setNewItem({ ...newItem, ConsentimentoInformado: 0 })
              }
            />{" "}
            Não
          </label>
        </div>
        <div className="consent-group">
          <label>
            Consentimento de Dados:
            <p>
              Este consentimento autoriza a coleta e o tratamento dos dados
              pessoais do aluno, como nome, contato e informações relevantes
              para sua matrícula e participação nas aulas.
            </p>
            <div className="form-input">
              <label>
                <input
                  type="radio"
                  name="consentimentodados"
                  value="1"
                  checked={newConsent.ConsentimentoDados === 1}
                  onChange={() => handleConsentChange("ConsentimentoDados", 1)}
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="consentimentodados"
                  value="0"
                  checked={newConsent.ConsentimentoDados === 0}
                  onChange={() => handleConsentChange("ConsentimentoDados", 0)}
                />{" "}
                Não
              </label>
            </div>
          </label>

          {/* Consentimento de Imagem */}
          <label>
            Consentimento de Imagem:
            <p>
              Este consentimento autoriza o uso da imagem do aluno em fotos e
              vídeos para fins de divulgação e promoção das atividades da
              instituição.
            </p>
            <div className="form-input">
              <label>
                <input
                  type="radio"
                  name="consentimentoimagem"
                  value="1"
                  checked={newConsent.ConsentimentoImagem === 1}
                  onChange={() => handleConsentChange("ConsentimentoImagem", 1)}
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="consentimentoimagem"
                  value="0"
                  checked={newConsent.ConsentimentoImagem === 0}
                  onChange={() => handleConsentChange("ConsentimentoImagem", 0)}
                />{" "}
                Não
              </label>
            </div>
          </label>

          {/* Consentimento Educacional */}
          <label>
            Consentimento Educacional:
            <p>
              Este consentimento autoriza o compartilhamento de informações
              relevantes sobre o progresso educacional do aluno com os
              responsáveis.
            </p>
            <div className="form-input">
              <label>
                <input
                  type="radio"
                  name="consentimentoeducacao"
                  value="1"
                  checked={newConsent.ConsentimentoEducativo === 1}
                  onChange={() =>
                    handleConsentChange("ConsentimentoEducativo", 1)
                  }
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="consentimentoeducacao"
                  value="0"
                  checked={newConsent.ConsentimentoEducativo === 0}
                  onChange={() =>
                    handleConsentChange("ConsentimentoEducativo", 0)
                  }
                />{" "}
                Não
              </label>
            </div>
          </label>
        </div>

        <button type="submit">Inscrever</button>
      </form>
    </div>
  );
};

export default EnrollForm;
