// src/components/ClassSlider.js
import React from 'react';
import Slider from 'react-slick';
import '../styles/ClassSlider.css'; // Adicione seus estilos customizados aqui
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ClassSlider = () => {
    const classes = [
        {
            image: '/assets/dance-517684_1280.jpg',
            title: 'Aulas de Dança',
            description: 'Venha se inscrever nas nossas energéticas aulas de dança e mexa-se como nunca!'
        },
        {
            image: '/assets/mic-1206364_1280.jpg',
            title: 'Aulas de Canto',
            description: 'Descubra sua voz nas nossas aulas de canto! Inscreva-se agora!'
        },
        {
            image: '/assets/online-6204349_1280.jpg',
            title: 'Aulas de TI',
            description: 'Aprenda tecnologia nas nossas aulas de TI e prepare-se para o futuro digital.'
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false // Remover setas
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {classes.map((classInfo, index) => (
                    <div key={index} className="slider-item">
                        <img src={classInfo.image} alt={classInfo.title} className="slider-image" />
                        <div className="slider-caption">
                            <h2>{classInfo.title}</h2>
                            <p>{classInfo.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ClassSlider;
