import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png"; // Adjust the path to your logo image
import "../styles/Login.css"; // Estilos para o layout e centralização

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Estado para alternar a visualização da senha
  const navigate = useNavigate(); // Hook para redirecionamento
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  // Função para alternar entre mostrar e esconder a senha
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação do e-mail
    if (!email.endsWith("@opah.pt")) {
      alert("O e-mail deve ser do domínio @opah.pt");
      return;
    }

    try {
      const res = await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        password,
      });

      localStorage.setItem("token", res.data.token); // Set the token
      localStorage.setItem("isLoggedIn", "true"); // Set isLoggedIn to true
      const userType = res.data.userType;
      localStorage.setItem("userType", res.data.userType); // Store the user type
      localStorage.setItem("userId", res.data.userId );


      // Redireciona com base no tipo de utilizador
      if (userType === "Administrador") {
        navigate("/administrador");
        console.log("Admin");
      } else if (userType === "Administrador Financeiro") {
        navigate("/administrador-financeiro");
      } else if (userType === "Professor") {
        navigate("/professor");
      } else if (userType === "Criador de Conteudo") {
        navigate("/suporte");
      }else if (userType === "Informatico") {
        navigate("/");
      } else {
        alert("Tipo de utilizador não reconhecido");
      }

      alert("Login bem-sucedido!");
      window.location.reload(); // Refresh the page after login
    } catch (err) {
      alert("Erro: " + err.response.data.msg);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Logo2" className="logo2" />{" "}
        {/* Logo da organização */}
        <h2>Connecte-se</h2> {/* Substituindo "Login" por "Connecte-se" */}
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="email"
            placeholder="Email da organização"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            type={showPassword ? "text" : "password"}
            placeholder="Palavra-Passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={togglePasswordVisibility}
          ></span>
          <button type="submit">Connectar-se</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
