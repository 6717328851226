// src/components/RegistrationForm.js
import React from 'react';

const RegistrationForm = () => {
    return (
        <div style={{ padding: '2rem' }}>
            <h1>Formulário de Inscrição</h1>
            <form>
                <label>
                    Nome:
                    <input type="text" name="name" required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" required />
                </label>
                <button type="submit">Inscrever-se</button>
            </form>
        </div>
    );
};

export default RegistrationForm;
