// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Component to protect routes for specific user roles
const ProtectedRoute = ({ children, allowedRoles }) => {
  // Get the user type and login status from localStorage
  const token = localStorage.getItem('token');
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'; // Check if the user is logged in
  const userType = localStorage.getItem('userType'); // Load the user type from localStorage
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  if (!isLoggedIn) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  // Check if userType is included in allowedRoles
  if (!allowedRoles.includes(userType)) {
    return <Navigate to="/" />; // Redirect if the user doesn't have the right role
  }

  return children; // Render the children components if allowed
};

export default ProtectedRoute;
