import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../styles/CreateNews.css";

const NewAula = () => {
  const location = useLocation();
  const aulaId = location.state?.aulaId;
  const moduloId = location.state?.moduloId;
  const professorId = location.state?.professorId;
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [newItem, setNewItem] = useState({
    Tema: "",
    DataHora: new Date().toISOString().slice(0, 16),
    Descricao: "",
    ModuloId: moduloId, // Manter ModuloId
    ProfessorId: professorId, // Manter ProfessorId
    Objectivo: "",
    Duracao: "",
  });

  // Função para formatar corretamente a data para o campo "datetime-local"
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // Formato esperado: "yyyy-MM-ddThh:mm"
  };

  // Carregar dados da aula existente ao editar
  useEffect(() => {
    if (aulaId) {
      axios
        .get(`${API_BASE_URL}/aulas/${aulaId}`)
        .then((res) => {
          const fetchedData = res.data;
          setNewItem({
            Tema: fetchedData.Tema || "", // Garantir que o valor é definido
            DataHora: formatDateTime(fetchedData.DataHora), // Formatar corretamente o campo DataHora
            Descricao: fetchedData.Descricao || "",
            ModuloId: fetchedData.ModuloId || moduloId,
            ProfessorId: fetchedData.ProfessorId || professorId,
            Objectivo: fetchedData.Objectivo || "",
            Duracao: fetchedData.Duracao || "",
          });
        })
        .catch((err) => console.error("Erro ao buscar aula", err));
    }
  }, [aulaId, moduloId, professorId]);

  // Função para salvar os dados (criar ou atualizar aula)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (aulaId) {
        // Atualizar aula existente
        await axios.put(`${API_BASE_URL}/aulas/${aulaId}`, newItem);
      } else {
        // Criar nova aula
        await axios.post(`${API_BASE_URL}/aulas`, newItem);
      }
      navigate("/professor");
    } catch (error) {
      console.error("Erro ao salvar a aula:", error);
    }
  };

  return (
    <div className="create-news-container">
      <h1>{aulaId ? `Editar Aula ${newItem.Tema}` : `Criar Aula no Módulo: ${moduloId}`}</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Tema"
          value={newItem.Tema}
          onChange={(e) => setNewItem({ ...newItem, Tema: e.target.value })}
          required
        />

        <input
          type="datetime-local"
          value={newItem.DataHora}
          onChange={(e) => setNewItem({ ...newItem, DataHora: e.target.value })}
          required
        />
        <ReactQuill
          value={newItem.Descricao}
          onChange={(content) => setNewItem({ ...newItem, Descricao: content })}
          placeholder="Escreva o conteúdo da aula..."
          className="quill"
          required
        />

        <input
          type="number"
          placeholder="Módulo"
          value={newItem.ModuloId}
          disabled
        />
        <input
          type="number"
          placeholder="Professor"
          value={newItem.ProfessorId}
          disabled
        />
        <ReactQuill
          value={newItem.Objectivo}
          onChange={(content) => setNewItem({ ...newItem, Objectivo: content })}
          placeholder="Escreva o Objectivo da aula..."
          className="quill"
          required
        />
        <input
          type="number"
          placeholder="Duração (minutos)"
          value={newItem.Duracao}
          onChange={(e) => setNewItem({ ...newItem, Duracao: e.target.value })}
        />

        <button type="submit">
          {aulaId ? "Atualizar Aula" : "Adicionar Aula"}
        </button>
      </form>
    </div>
  );
};

export default NewAula;
