import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/ClassSidebar.css";

const ClassSidebar = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/futuras`);
        setClasses(response.data);
      } catch (err) {
        setError("Erro ao carregar aulas");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  if (loading) {
    return <div>Carregando aulas...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="classbar-sidebar">
      <h2>Aulas Disponíveis</h2>
      <ul>
        {classes.map((classItem) => (
          <li key={classItem.id}>
            <Link to={`/aulas/${classItem.id}`}>
              {classItem.Tema}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClassSidebar;
