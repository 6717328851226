import React from "react";
import ContactForm from "./ContactForm";
import "../styles/JoinUs.css"; // Make sure to create this CSS file
// Importe o formulário de contato se você quiser usar o mesmo formulário

const JoinUs = () => {
  return (
    <div className="join-us-container">
      <p>
        Se deseja tornar-se um associado ou contactar-nos, por favor preencha o formulário abaixo
        para entrar em contato connosco.
      </p>
      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default JoinUs;
