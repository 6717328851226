import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import "../styles/ClassDetails.css"; // Adicione seus estilos aqui

const ClassDetails = () => {
  const { id } = useParams(); // Get class ID from URL
  const [selectedClass, setSelectedClass] = useState(null);
  const [professor, setProfessor] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const classResponse = await axios.get(`${API_BASE_URL}/aulas/${id}`);
        setSelectedClass(classResponse.data);

        // Fetch professor details
        const professorResponse = await axios.get(
          `${API_BASE_URL}/professores/${classResponse.data.ProfessorId}`
        );
        setProfessor(professorResponse.data);

        // Fetch user details if available
        if (professorResponse.data.UsuarioId) {
          const userResponse = await axios.get(
            `${API_BASE_URL}/usuarios/${professorResponse.data.UsuarioId}`
          );
          setUsuario(userResponse.data);
        }
      } catch (err) {
        console.error("Erro ao carregar informações da aula", err);
      }
    };

    fetchClassDetails();
  }, [id]);

  if (!selectedClass) {
    return <div className="loading">Carregando detalhes da aula...</div>; // Added class for loading state
  }

  return (
    <div className="class-details"> {/* Added class for styling */}
      <h2 className="class-title">{selectedClass.Tema}</h2> {/* Class for title */}
      <div className="class-description" dangerouslySetInnerHTML={{ __html: selectedClass.Descricao }} />
      <p className="class-duration"><strong>Duração:</strong> {selectedClass.Duracao}</p>
      <p><strong>Objectivo:</strong> </p>
      <div className="class-description" dangerouslySetInnerHTML={{ __html: selectedClass.Objectivo }} />

      {/* Display professor information */}
      {professor && usuario && (
        <div className="professor-info"> {/* Class for professor info */}
          <h3 className="professor-title">Informações do Professor</h3> {/* Class for professor title */}
          <p className="professor-name"><strong>Nome:</strong> {usuario.Nome} {usuario.Sobrenome}</p>
          <p className="professor-skills"><strong>Competências:</strong> {professor.Competencias}</p>
          <p className="professor-certifications"><strong>Certificações:</strong> {professor.Certificacoes}</p>
          <p className="professor-email"><strong>Email:</strong> {usuario.Email}</p>
        </div>
      )}

      <Link to={`/inscrever/${id}`}>
        <button className="enroll-button">Inscrever-se</button> {/* Class for button */}
      </Link>
    </div>
  );
};

export default ClassDetails;
