import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Noticias from "./components/Noticias";
import Home from "./components/Home";
import About from "./components/About";
import Classes from "./components/Classes";
import Login from "./components/Login";
import Donate from "./components/Donate";
import JoinUs from "./components/JoinUs";
import RegistrationForm from "./components/RegistrationForm";
import Footer from "./components/Footer";
import Administrador from "./components/Administrador";
import AdminFinanceiro from "./components/AdministradorFinanceiro";
import Professor from "./components/Professor";
import NewAula from "./components/NewAula";
import NewModulo from "./components/NewModulo";
import Alunos from "./components/Alunos";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Informatico from "./components/Informatico";
import ClassDetails from "./components/ClassDetails";
import EnrollForm from "./components/EnrollForm";
import Parcerias from "./components/Parcerias";
import CreateNews from "./components/CreateNews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <MainApp />
      </Router>
    </AuthProvider>
  );
};

const MainApp = () => {
  const location = useLocation();

  return (
    <div className="app">
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/parcerias" element={<Parcerias />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/aulas" element={<Classes />} />
          <Route path="/inscricao" element={<RegistrationForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/doar" element={<Donate />} />
          <Route path="/contato" element={<JoinUs />} />
          <Route path="/associarse" element={<JoinUs />} />
          <Route path="/aulas/:id" element={<ClassDetails />} />
          <Route path="/inscrever/:id" element={<EnrollForm />} />

          {/* Protected Routes for Admin */}
          <Route
            path="/administrador"
            element={
              <ProtectedRoute allowedRoles={["Administrador"]}>
                <Administrador />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-news"
            element={
              <ProtectedRoute allowedRoles={["Criador de Conteudo", "Administrador"]}>
                <CreateNews />
              </ProtectedRoute>
            }
          />

          {/* Protected Routes for Admin Financeiro */}
          <Route
            path="/administrador-financeiro"
            element={
              <ProtectedRoute allowedRoles={["Administrador Financeiro"]}>
                <AdminFinanceiro />
              </ProtectedRoute>
            }
          />

          {/* Protected Routes for Professores */}
          <Route
            path="/professor"
            element={
              <ProtectedRoute allowedRoles={["Professor"]}>
                <Professor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-aula"
            element={
              <ProtectedRoute allowedRoles={["Professor"]}>
                <NewAula />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-aula"
            element={
              <ProtectedRoute allowedRoles={["Professor"]}>
                <NewAula />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-modulo"
            element={
              <ProtectedRoute allowedRoles={["Professor"]}>
                <NewModulo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alunos/:aulaId"
            element={
              <ProtectedRoute allowedRoles={["Professor"]}>
                <Alunos/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/suporte"
            element={
              <ProtectedRoute allowedRoles={["Informatico"]}>
                <Informatico />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      {location.pathname === '/' && <Footer />}
    </div>
  );
};

export default App;
