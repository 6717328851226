import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faPencilAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/Administrador.css"; // Import CSS here
import CalculoBalancete from "./CalculoBalancete";

const AdministradorFinanceiro = () => {
  const [selectedResource, setSelectedResource] = useState("faturas");
  const [data, setData] = useState([]);
  const [faturas, setFaturas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [newItem, setNewItem] = useState({});
  const [editItemId, setEditItemId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [years, setYears] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const fetchData = async (resource) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${resource}`);
      const allData = response.data;
      setData(allData);

      if (resource === "faturas") {
        const uniqueYears = [
          ...new Set(
            allData.map((item) => new Date(item.DataHora).getFullYear())
          ),
        ];
        setYears(uniqueYears);
        setSelectedYear(uniqueYears[0] || "");
      }
    } catch (error) {
      console.error(`Error fetching ${resource}:`, error);
    }
  };

  const fetchFaturas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/faturas`);
      setFaturas(response.data);
    } catch (error) {
      console.error("Erro ao buscar as faturas:", error);
    }
  };

  useEffect(() => {
    fetchFaturas();
    fetchData(selectedResource);
  }, [selectedResource]);

  const handleEdit = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setEditItemId(id);
    setEditedData(itemToEdit);
  };

  const handleSave = async (id) => {
    try {
      await axios.put(
        `${API_BASE_URL}/${selectedResource}/${id}`,
        editedData
      );
      fetchData(selectedResource);
      setEditItemId(null);
    } catch (error) {
      console.error("Erro ao salvar as alterações:", error);
    }
  };

  const handleCancel = () => {
    setEditItemId(null);
    setEditedData({});
  };

  const handleCreate = async () => {
    try {
      await axios.post(
        `${API_BASE_URL}/${selectedResource}`,
        newItem
      );
      fetchData(selectedResource);
      setNewItem({});
    } catch (error) {
      console.error(`Error creating ${selectedResource}:`, error);
    }
  };

  const filteredData = data
    .filter((item) => {
      const itemDate = new Date(item.DataHora);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth() + 1; // Os meses em JavaScript são indexados a partir de 0

      const yearMatch = selectedYear
        ? itemYear === parseInt(selectedYear)
        : true;
      const monthMatch = selectedMonth
        ? itemMonth === parseInt(selectedMonth)
        : true;

      return yearMatch && monthMatch;
    })
    .filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

  return (
    <div className="administrador-container">
      <div className="topbar">
        <h2>Recursos Financeiros</h2>
        <ul className="nav-buttons">
          <li
            onClick={() => setSelectedResource("faturas")}
            className="sidebar-button"
          >
            Faturas
          </li>
          <li
            onClick={() => setSelectedResource("balancete")}
            className="sidebar-button"
          >
            Balancetes
          </li>
        </ul>
      </div>

      <div className="main-content">
        <h1>
          {selectedResource.charAt(0).toUpperCase() + selectedResource.slice(1)}
        </h1>

        {/* Search Bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Select Year Dropdown */}
        {
          <div className="year-selector">
            <label htmlFor="year-select">Selecionar Ano:</label>
            <select
              id="year-select"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        }

        <div className="month-selector">
          <label htmlFor="month-select">Selecionar Mês:</label>
          <select
            id="month-select"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">Todos os Meses</option>{" "}
            {/* Opção para todos os meses */}
            {Array.from({ length: 12 }, (_, index) => {
              const month = index + 1; // Meses de 1 a 12
              return (
                <option key={month} value={month}>
                  {month.toString().padStart(2, "0")}{" "}
                  {/* Formato 01, 02, ..., 12 */}
                </option>
              );
            })}
          </select>
        </div>

        <table className="table-container">
          <thead>
            <tr>
              {selectedResource === "faturas" && (
                <>
                  <th>ID</th>
                  <th>Número de Fatura</th>
                  <th>NIF</th>
                  <th>Descrição</th>
                  <th>Data e Hora</th>
                  <th>Valor</th>
                </>
              )}
              {selectedResource === "balancete" && (
                <>
                  <th>ID</th>
                  <th>Data e Hora</th>
                  <th>Montante</th>
                  <th>Entrada ou Saída</th>
                  <th>Fatura</th>
                </>
              )}
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                {selectedResource === "balancete" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "),
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString()
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Montante || item.Montante}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Montante: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Montante
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={editedData.EntradaSaida || item.EntradaSaida}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              EntradaSaida: e.target.value,
                            })
                          }
                        >
                          <option value="Entrada">Entrada</option>
                          <option value="Saída">Saída</option>
                        </select>
                      ) : (
                        item.EntradaSaida
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={editedData.FaturaId || item.FaturaId}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              FaturaId: e.target.value,
                            })
                          }
                        >
                          {faturas.map((fatura) => (
                            <option key={fatura.id} value={fatura.id}>
                              {fatura.NumeroFatura} - {fatura.Valor}€
                            </option>
                          ))}
                        </select>
                      ) : (
                        item.FaturaId
                      )}
                    </td>
                  </>
                )}
                {selectedResource === "faturas" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.NumeroFatura || item.NumeroFatura}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NumeroFatura: e.target.value,
                            })
                          }
                          required
                        />
                      ) : (
                        item.NumeroFatura
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.NifVendedor || item.NifVendedor}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NifVendedor: e.target.value,
                            })
                          }
                          required
                        />
                      ) : (
                        item.NifVendedor
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Descricao || item.Descricao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Descricao: e.target.value,
                            })
                          }
                          required
                        />
                      ) : (
                        item.Descricao
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "),
                            })
                          }
                          required
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString()
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Valor || item.Valor}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Valor: e.target.value,
                            })
                          }
                          required
                        />
                      ) : (
                        item.Valor
                      )}
                    </td>
                  </>
                )}
                <td>
                  {editItemId === item.id ? (
                    <>
                      <button onClick={() => handleSave(item.id)}>
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                      <button onClick={handleCancel}>
                        <FontAwesomeIcon icon={faCancel} />
                      </button>
                    </>
                  ) : (
                    <button onClick={() => handleEdit(item.id)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Form for Creating New Item */}
        <div className="new-item-form">
          <h3>Criar Novo Item</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            {selectedResource === "faturas" && (
              <>
                <input
                  type="text"
                  placeholder="Número da Fatura"
                  value={newItem.NumeroFatura || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, NumeroFatura: e.target.value })
                  }
                  required
                />
                <input
                  type="number"
                  placeholder="NIF"
                  value={newItem.NifVendedor || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, NifVendedor: e.target.value })
                  }
                  required
                />
                <input
                  type="text"
                  placeholder="Descrição"
                  value={newItem.Descricao || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, Descricao: e.target.value })
                  }
                  required
                />
                <input
                  type="datetime-local"
                  value={newItem.DataHora || ""}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      DataHora: new Date(e.target.value)
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                    })
                  }
                  required
                />
                <input
                  type="number"
                  placeholder="Valor"
                  value={newItem.Valor || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, Valor: e.target.value })
                  }
                  required
                />
              </>
            )}
            {selectedResource === "balancete" && (
              <>
                <input
                  type="datetime-local"
                  placeholder="Data e Hora"
                  value={newItem.DataHora || ""}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      DataHora: new Date(e.target.value)
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                    })
                  }
                  required
                />
                <input
                  type="number"
                  placeholder="Montante"
                  value={newItem.montante || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, montante: e.target.value })
                  }
                  required
                />
                <select
                  value={newItem.entrada_saida || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, entrada_saida: e.target.value })
                  }
                  required
                >
                  <option value="">Selecione...</option>
                  <option value="Entrada">Entrada</option>
                  <option value="Saída">Saída</option>
                </select>
                <select
                  value={newItem.fatura || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, fatura: e.target.value })
                  }
                  required
                >
                  <option value="">Selecione Fatura...</option>
                  {faturas.map((fatura) => (
                    <option key={fatura.id} value={fatura.id}>
                      {fatura.NumeroFatura} - {fatura.Valor}€
                    </option>
                  ))}
                </select>
              </>
            )}

            <button onClick={handleCreate}>Criar</button>
          </form>
        </div>
      </div>
      <div className="balancete-calculo">
          <CalculoBalancete />
        </div>
    </div>
  );
};

export default AdministradorFinanceiro;
