// src/components/CreateNews.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import '../styles/CreateNews.css'; // Import custom CSS for the form

const CreateNews = () => {
    const [newItem, setNewItem] = useState({
        Titulo: '',
        FotoOuVideo: '',
        Conteudo: '',
        DataHora: new Date().toISOString().slice(0, 16), // Set current date and time
        Local: '',
        Contacto: ''
    });
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // Geolocation API to get current location
    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        // Using OpenStreetMap's Nominatim API for reverse geocoding
                        const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
                        const address = response.data.display_name;
                        setNewItem((prevState) => ({ ...prevState, Local: address }));
                    } catch (error) {
                        console.error("Erro ao obter o endereço:", error);
                    }
                }, (error) => {
                    console.error("Erro ao obter a geolocalização:", error);
                });
            } else {
                console.log("Geolocalização não é suportada pelo navegador.");
            }
        };

        getLocation();
    }, []);

    // Handle file upload
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let filePath = '';
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
        
            try {
                const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
        
                console.log('Upload de arquivo bem-sucedido:', response.data);
        
                if (response.data.filename) {
                    filePath = response.data.filename; // Store the filename for further use
                } else {
                    console.error('Nome do arquivo não retornado:', response.data);
                    return; // Stop submission if filename is not returned
                }
            } catch (error) {
                console.error('Erro ao enviar o arquivo:', error);
                return; // Stop submission if upload fails
            }
        }
        
        // Submit the rest of the form data
        try {
            const result = await axios.post(`${API_BASE_URL}/publicacoes`, {
                ...newItem,
                FotoOuVideo: filePath,
            });
            console.log('Publicação criada com sucesso:', result.data);
            navigate('/noticias');
        } catch (error) {
            console.error('Erro ao criar a publicação:', error);
        }
    };
    

    return (
        <div className="create-news-container">
            <h1>Criar Nova Publicação</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Título"
                    value={newItem.Titulo}
                    onChange={(e) => setNewItem({ ...newItem, Titulo: e.target.value })}
                    required
                />

                <input
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                />

                <ReactQuill
                    value={newItem.Conteudo}
                    onChange={(content) => setNewItem({ ...newItem, Conteudo: content })}
                    placeholder="Escreva o conteúdo da notícia..."
                    className='quill'
                    required
                />

                <input
                    type="datetime-local"
                    value={newItem.DataHora}
                    onChange={(e) => setNewItem({ ...newItem, DataHora: e.target.value })}
                    required
                />

                <input
                    type="text"
                    placeholder="Local"
                    value={newItem.Local}
                    onChange={(e) => setNewItem({ ...newItem, Local: e.target.value })}
                    required
                />

                <input
                    type="text"
                    placeholder="Contato"
                    value={newItem.Contacto}
                    onChange={(e) => setNewItem({ ...newItem, Contacto: e.target.value })}
                />

                <button type="submit">Adicionar Publicação</button>
            </form>
        </div>
    );
};

export default CreateNews;
