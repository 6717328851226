import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../styles/CreateNews.css";

const NewModulo = () => {
  const location = useLocation();
  const moduloId = location.state?.moduloId;
  const professorId = location.state?.professorId;
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [newItem, setNewItem] = useState({
    Nome: "",
    Descricao: "",
    ProfessorId: professorId,
  });

  useEffect(() => {
    if (moduloId) {
      // If editing, fetch the existing data for the modulo
      axios
        .get(`${API_BASE_URL}/modulos/${moduloId}`)
        .then((res) => {
          const fetchedData = res.data;
          setNewItem(fetchedData); // Use newItem state directly
        })
        .catch((err) => console.error("Erro ao buscar modulo", err));
    }
  }, [moduloId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (moduloId) {
        // Update existing modulo
        await axios.put(
          `${API_BASE_URL}/modulos/${moduloId}`,
          newItem // Use newItem state directly
        );
      } else {
        // Create new modulo
        await axios.post(`${API_BASE_URL}/modulos`, newItem); // Use newItem state directly
      }
      navigate("/professor");
    } catch (error) {
      console.error("Erro ao salvar o modulo:", error.response?.data || error);
    }
  };

  return (
    <div className="create-news-container">
      <h1>{moduloId ? `Editar Módulo ${newItem.Nome}` : `Criar Módulo`}</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome do Módulo"
          value={newItem.Nome}
          onChange={(e) => setNewItem({ ...newItem, Nome: e.target.value })}
          required
        />
        <ReactQuill
          value={newItem.Descricao}
          onChange={(content) => setNewItem({ ...newItem, Descricao: content })}
          placeholder="Escreva a descrição do módulo..."
          className="quill"
          required
        />
        <input
          type="number"
          placeholder="Professor"
          value={professorId}
          onChange={(e) => setNewItem({ ...newItem, ProfessorId: professorId })}
          hidden
          disabled
        />
        <button type="submit">{moduloId ? "Atualizar Módulo" : "Adicionar Módulo"}</button>
      </form>
    </div>
  );
};

export default NewModulo;
