import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/Alunos.css"

const Alunos = () => {
  const { aulaId } = useParams(); // Get aulaId from URL
  const [students, setStudents] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // State to track edit mode
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}//alunos/aulas/${aulaId}`); // Fetch students by aulaId
        setStudents(res.data);
      } catch (err) {
        console.error("Erro ao buscar alunos", err);
      }
    };

    fetchStudents();
  }, [aulaId]);

  const handlePresenceChange = (studentId, presence) => {
    // Update the presence in the students array without sending it to the backend yet
    const updatedStudents = students.map((student) =>
      student.id === studentId ? { ...student, presence } : student
    );
    setStudents(updatedStudents);
  };

  const savePresence = async () => {
    // Send updated presence statuses to the backend
    try {
      await Promise.all(
        students.map((student) =>
          axios.put(`${API_BASE_URL}//alunos/${student.id}`, { presence: student.presence })
        )
      );
      setIsEditing(false); // Exit edit mode after saving
    } catch (err) {
      console.error("Erro ao atualizar presença", err);
    }
  };

  return (
    <div className="alunos-container">
      <h2 className="alunos-h2">Alunos Inscritos</h2>
      <button className="alunos-button" onClick={() => setIsEditing((prev) => !prev)}>
        {isEditing ? "Cancelar Edição" : "Editar Presença"}
      </button>
  
      <table className="alunos-table">
        <thead className="alunos-thead">
          <tr className="alunos-tr">
            <th className="alunos-th">Nome</th>
            <th className="alunos-th">Contacto</th>
            <th className="alunos-th">Email</th>
            <th className="alunos-th">Presença</th>
          </tr>
        </thead>
        <tbody className="alunos-tbody">
          {students.map((student) => (
            <tr className="alunos-tr" key={student.id}>
              <td className="alunos-td" data-label="Nome">{`${student.Nome} ${student.Sobrenome}`}</td>
              <td className="alunos-td" data-label="Contacto">{student.Telefone}</td>
              <td className="alunos-td" data-label="Email">{student.EmailEncEdu}</td>
              <td className="alunos-td" data-label="Presença">
                {isEditing ? (
                  <select
                    className="alunos-select"
                    value={student.presence}
                    onChange={(e) => handlePresenceChange(student.id, e.target.value)}
                  >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                ) : (
                  <span>{student.presence === "1" ? "Sim" : "Não"}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
      {isEditing && <button className="alunos-button" onClick={savePresence}>Gravar Presença</button>}
      <button className="alunos-button" onClick={() => navigate(-1)}>Voltar</button>
    </div>
  );
  
};

export default Alunos;
