import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css"; // Certifique-se de que o arquivo CSS está vinculado corretamente
import backgroundVideo from "../assets/150592-798876909_medium.mp4"; // Ajuste o caminho do vídeo

const Home = () => {
  return (
    <div>
    <div className="background-video">
      <video autoPlay loop muted playsInline className="video-background">
        <source src={backgroundVideo} type="video/mp4" />
        Seu navegador não suporta o vídeo.
      </video>
      </div>
      <div className="overlay">
        <div className="centered-content">
          <h1>Bem-vindo à OPAH</h1>
          <h3>Plante a ajuda , colha o futuro!</h3>
          <div className="buttons-container">
            <Link to="/noticias" className="button">
              Notícias
            </Link>
            <Link to="/doar" className="button">
              Doe
            </Link>
            <Link to='/associarse' className="button">
            Associe-se
            </Link>
            <Link to="/contato" className="button">
              Contacte-nos
            </Link>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Home;
