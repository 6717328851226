// src/components/Parcerias.js
import React from 'react';
import '../styles/Parcerias.css'; // Adicione seus estilos personalizados aqui

const Parcerias = () => {
    return (
        <div className='parcerias-title'>
            <div className="parcerias-container">
                <div className="parceria">
                    <h2>Câmara Municipal de Braga</h2>
                    <img src="../assets/logo-braga-1.png" alt="Instituição Parceira" className="parceria-image" />
                    <h3>Âmbito da Parceria</h3>
                    <p>A Câmara Municipal de Braga, através da Bragahabit e do projeto Human Power Hub, apoia o nosso projeto "Turma do Bairro". Esta colaboração visa promover o desenvolvimento social e cultural da comunidade, fornecendo oportunidades educacionais e de formação. A parceria fortalece o compromisso com a inclusão, incentivando a participação em atividades educativas, como aulas e workshops, que contribuem para o empoderamento das pessoas e o fortalecimento do tecido comunitário local.</p>
                    <a href="https://www.cm-braga.pt/pt/0201/home/noticias/item/item-1-15115" target="_blank" rel="noopener noreferrer" className="visitar-parceiro-button">
                        Visitar Parceiro
                    </a>
                </div>
                
                <div className="parceria">
                    <h2>ADMS Braga</h2>
                    <img src="../assets/ADMS.png" alt="Instituição Parceira" className="parceria-image" />
                    <h3>Âmbito da Parceria</h3>
                    <p>
                        A Assembleia de Deus - Ministério de Santos, uma igreja evangélica comprometida com a fé e o bem-estar da comunidade, apoia o projeto "Turma do Bairro" através de iniciativas sociais e educacionais. A parceria busca promover valores cristãos de solidariedade e amor ao próximo, oferecendo apoio espiritual e prático para aqueles que participam das atividades do projeto. Juntos, trabalhamos para proporcionar um ambiente acolhedor e inclusivo, que fomente o crescimento pessoal e comunitário por meio da educação e do desenvolvimento de habilidades.
                    </p>
                    <a href="https://www.facebook.com/ADMSBRAGAPT/" target="_blank" rel="noopener noreferrer" className="visitar-parceiro-button">
                        Visitar Parceiro
                    </a>
                </div>
                
                <div className="parceria">
                    <h2>Human Power Hub</h2>
                    <img src="../assets/HPH-01.png" alt="Instituição Parceira" className="parceria-image" />
                    <h3>Âmbito da Parceria</h3>
                    <p>
                        A Human Power Hub tem sido uma peça fundamental no desenvolvimento e crescimento do nosso projeto Turma do Bairro. Como incubadora, a Human Power Hub forneceu o suporte necessário para a implementação de iniciativas de impacto social e comunitário, possibilitando a criação de um espaço onde jovens e adultos possam adquirir novas habilidades e conhecimentos através das nossas atividades e programas.
                    </p>
                    <a href="https://humanpowerhub.pt/" target="_blank" rel="noopener noreferrer" className="visitar-parceiro-button">
                        Visitar Parceiro
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Parcerias;
