import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
export const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap the application
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);

  // Load token and userType from localStorage when the app starts
  useEffect(() => {
    const token = localStorage.getItem('token');
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true'; // Check if the user is logged in
     // Load the user type from localStorage

    if (token && loggedIn) {
      const storedUserType = localStorage.getItem('userType');
      setIsLoggedIn(true);
      setUserType(storedUserType);
    }
  }, []);

  // Provide context values to the rest of the application
  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userType, setUserType }}>
      {children}
    </AuthContext.Provider>
  );
};
