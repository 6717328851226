// src/components/Classes.js
import React from 'react';
import ClassSidebar from './ClassSidebar';
import ClassSlider from './ClassSlider';
import '../styles/Classes.css';

const Classes = () => {
    return (
        <div className='classes-components'>
            <div className='slider-component'>
                <ClassSlider />
            </div>
            <div className='sidebar-component'>
                <ClassSidebar />
            </div>
        </div>
    );
};

export default Classes;
