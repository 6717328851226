import React, { useState } from "react";
import "../styles/ContactForm.css"; // Adicione seus estilos aqui
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ContactForm = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    assunto: "",
    descricao: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { nome, email, assunto, descricao } = formData;

    // Envie os dados do formulário para um endpoint ou API aqui
    // Exemplo de envio de email (precisaria de backend para funcionar)
    window.location.href = `mailto:geral@opah.pt?subject=${assunto}&body=Nome: ${nome}%0AEmail: ${email}%0ADescrição: ${descricao}`;

    // Resetando o formulário após o envio
    setFormData({
      nome: "",
      email: "",
      assunto: "",
      descricao: "",
    });
  };

  return (
    <div className="contacr-page">
      <div className="contact-form-container">
        <h1>Contacte-nos</h1>
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="nome"
            placeholder="Seu Nome"
            value={formData.nome}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Seu Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="assunto"
            placeholder="Assunto"
            value={formData.assunto}
            onChange={handleChange}
            required
          />
          <textarea
            name="descricao"
            placeholder="Descrição"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
