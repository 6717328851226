import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/Administrador.css"; // Import CSS here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faPencilAlt,
  faSave,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import the icons you need

const Administrador = () => {
  const [selectedResource, setSelectedResource] = useState("usuarios");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [newItem, setNewItem] = useState({});
  const [editItemId, setEditItemId] = useState(null); // ID do item em edição
  const [editedData, setEditedData] = useState({}); // Dados editados
  const [aulas, setAulas] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchAulas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/aulas`); // Substituir pela URL correta da API
      setAulas(response.data); // Supondo que a resposta da API seja um array de aulas
    } catch (error) {
      console.error("Erro ao buscar as aulas:", error);
    }
  };

  // Function to fetch data based on the selected resource
  const fetchData = async (resource) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${resource}`);
      setData(response.data);
    } catch (error) {
      console.error(`Error fetching ${resource}:`, error);
    }
  };

  // UseEffect to fetch data when the resource changes
  useEffect(() => {
    fetchAulas();
    fetchData(selectedResource);
  }, [selectedResource]);

  const handleEdit = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setEditItemId(id);
    setEditedData(itemToEdit); // Preenche os dados do item a ser editado
  };
  const handleSave = async (id) => {
    try {
      await axios.put(
        `${API_BASE_URL}/${selectedResource}/${id}`,
        editedData
      );
      fetchData(selectedResource); // Atualiza os dados após a edição
      setEditItemId(null); // Fecha o modo de edição
    } catch (error) {
      console.error("Erro ao salvar as alterações:", error);
    }
  };
  const handleCancel = () => {
    setEditItemId(null); // Sai do modo de edição
    setEditedData({}); // Reseta os dados editados
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${selectedResource}/${id}`);
      console.log(`Successfully deleted item with id: ${id}`);
      fetchData(selectedResource); // Refresh data after deletion
    } catch (error) {
      console.error(`Error deleting ${selectedResource} with id ${id}:`, error.response?.data || error.message);
    }
  };
  

  // Handle new item creation
  const handleCreate = async () => {
    try {
      await axios.post(
        `${API_BASE_URL}/${selectedResource}`,
        newItem
      );
      fetchData(selectedResource); // Refresh data after creation
      setNewItem({}); // Reset the newItem state
    } catch (error) {
      console.error(`Error creating ${selectedResource}:`, error);
    }
  };

  // Filter data based on the search term
  const filteredData = data.filter((item) => {
    return Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className="administrador-container">
      <div className="topbar">
        <h2>Gerenciamento de Recursos</h2>
        <ul class="nav-buttons">
          <li
            onClick={() => setSelectedResource("faturas")}
            class="sidebar-button"
          >
            Faturas
          </li>
          <li
            onClick={() => setSelectedResource("balancete")}
            class="sidebar-button"
          >
            Balancetes
          </li>
          <li
            onClick={() => setSelectedResource("modulos")}
            class="sidebar-button"
          >
            Módulos
          </li>
          <li
            onClick={() => setSelectedResource("relatorios")}
            class="sidebar-button"
          >
            Relatórios
          </li>
          <li
            onClick={() => setSelectedResource("consentimentos")}
            class="sidebar-button"
          >
            Consentimentos
          </li>
          <li
            onClick={() => setSelectedResource("usuarios")}
            class="sidebar-button"
          >
            Usuários
          </li>
          <li
            onClick={() => setSelectedResource("aulas")}
            class="sidebar-button"
          >
            Aulas
          </li>
          <li
            onClick={() => setSelectedResource("professores")}
            class="sidebar-button"
          >
            Professores
          </li>
          <li
            onClick={() => setSelectedResource("alunos")}
            class="sidebar-button"
          >
            Alunos
          </li>
          <li
            onClick={() => setSelectedResource("publicacoes")}
            class="sidebar-button"
          >
            Publicações
          </li>
        </ul>
      </div>

      <div className="main-content">
        <h1>
          {selectedResource.charAt(0).toUpperCase() + selectedResource.slice(1)}
        </h1>

        {/* Search Bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table className="table-container">
          <thead>
            <tr>
              {selectedResource === "faturas" && (
                <>
                  <th>ID</th>
                  <th>Número de Fatura</th>
                  <th>NIF</th>
                  <th>Descrição</th>
                  <th>Data e Hora</th>
                  <th>Valor</th>
                </>
              )}
              {selectedResource === "usuarios" && (
                <>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Sobrenome</th>
                  <th>NIF</th>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>Endereço</th>
                  <th>Cidade</th>
                  <th>NSS</th>
                  <th>Tipo de Utilizador</th>
                  <th>Hashed Password</th>
                </>
              )}
              {selectedResource === "publicacoes" && (
                <>
                  <th>ID</th>
                  <th>Título</th>
                  <th>Anexo</th>
                  <th>Conteúdo</th>
                  <th>Data e Hora</th>
                  <th>Local</th>
                  <th>Contacto</th>
                </>
              )}
              {selectedResource === "relatorios" && (
                <>
                  <th>ID</th>
                  <th>Modulo</th>
                  <th>Numero de participantes</th>
                  <th>Data de Inicio</th>
                  <th>Data de Fim</th>
                  <th>Descrição</th>
                </>
              )}
              {selectedResource === "aulas" && (
                <>
                  <th>ID</th>
                  <th>Tema</th>
                  <th>DataHora</th>
                  <th>Descricao</th>
                  <th>ModuloId</th>
                  <th>ProfessorId</th>
                  <th>Objectivo</th>
                  <th>Duracao</th>
                </>
              )}
              {selectedResource === "professores" && (
                <>
                  <th>ID</th>
                  <th>Usuário ID</th>
                  <th>Competências</th>
                  <th>Certificações</th>
                </>
              )}
              {selectedResource === "modulos" && (
                <>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Professor</th>
                </>
              )}
              {selectedResource === "consentimentos" && (
                <>
                  <th>ID</th>
                  <th>Consentimento Direitos de Imagem</th>
                  <th>Consentimento Educativo</th>
                  <th>Consentimento Uso de Dados</th>
                </>
              )}
              {selectedResource === "balancete" && (
                <>
                  <th>ID</th>
                  <th>Data e Hora</th>
                  <th>Montante</th>
                  <th>Entrada ou Saída</th>
                  <th>Fatura</th>
                </>
              )}
              {selectedResource === "alunos" && (
                <>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>NIF</th>
                  <th>Número de Utente</th>
                  <th>Telefone</th>
                  <th>Endereço</th>
                  <th>Contato de Emergência</th>
                  <th>Aula</th>
                  <th>Consentimento</th>
                  <th>Presença</th>
                  <th>Nome do Encarregado de Educação</th>
                  <th>Profissão do Encarregado de Educação</th>
                  <th>Email do Encarregado de Educação</th>
                  <th>Telefone do Encarregado de Educação</th>
                  <th>Escolaridade do Encarregado de Educação</th>
                  <th>Grau Parentesco</th>
                  <th>Escola que o Aluno Frequenta</th>
                  <th>Tem Doenças Crónicas</th>
                  <th>Como Conheceu o Programa</th>
                  <th>Consentimentos</th>
                </>
              )}
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                {selectedResource === "faturas" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.NumeroFatura || item.NumeroFatura}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NumeroFatura: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NumeroFatura
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.NifVendedor || item.NifVendedor}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NifVendedor: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NifVendedor
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Descricao || item.Descricao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Descricao: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Descricao
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16) // Convert to "YYYY-MM-DDTHH:MM"
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "), // Convert to "YYYY-MM-DD HH:MM:SS" for MySQL
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString() // Display in readable format
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Valor || item.Valor}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Valor: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Valor
                      )}
                    </td>
                  </>
                )}
                {selectedResource === "relatorios" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.ModuloId || item.ModuloId}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ModuloId: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.ModuloId
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={
                            editedData.NumeroParticipantes ||
                            item.NumeroParticipantes
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NumeroParticipantes: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NumeroParticipantes
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataInicio
                              ? new Date(editedData.DataInicio)
                                  .toISOString()
                                  .slice(0, 16) // Convert to "YYYY-MM-DDTHH:MM"
                              : new Date(item.DataInicio)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataInicio: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "), // Convert to "YYYY-MM-DD HH:MM:SS" for MySQL
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataInicio).toLocaleString() // Display in readable format
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataFim
                              ? new Date(editedData.DataFim)
                                  .toISOString()
                                  .slice(0, 16) // Convert to "YYYY-MM-DDTHH:MM"
                              : new Date(item.DataFim)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataFim: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "), // Convert to "YYYY-MM-DD HH:MM:SS" for MySQL
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataFim).toLocaleString() // Display in readable format
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Descricao || item.Descricao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Descricao: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Descricao
                      )}
                    </td>
                  </>
                )}
                {selectedResource === "usuarios" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Nome || item.Nome}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Nome: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Nome
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Sobrenome || item.Sobrenome}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Sobrenome: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Sobrenome
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.NIF || item.NIF}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NIF: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NIF
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Telefone || item.Telefone}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Telefone: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Telefone
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Email || item.Email}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Email: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Email
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Endereco || item.Endereco}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Endereco: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Endereco
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Cidade || item.Cidade}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Cidade: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Cidade
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.NSS || item.NSS}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NSS: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NSS
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={
                            editedData.TipoUtilizador || item.TipoUtilizador
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              TipoUtilizador: e.target.value,
                            })
                          }
                        >
                          <option value="Administrador">Administrador</option>
                          <option value="Informatico">Informático</option>
                          <option value="Criador de Conteudo">
                            Criador de Conteudo
                          </option>
                          <option value="Administrador Financeiro">
                            Administrador Financeiro
                          </option>
                          <option value="Professor">Professor</option>
                        </select>
                      ) : (
                        item.TipoUtilizador
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="password"
                          value={editedData.Password || item.Password}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Password: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Password
                      )}
                    </td>
                  </>
                )}
                {selectedResource === "publicacoes" && (
                  <>
                    <td>{item.id}</td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Titulo || item.Titulo}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Titulo: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Titulo
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.FotoOuVideo || item.FotoOuVideo}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              FotoOuVideo: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.FotoOuVideo
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Conteudo || item.Conteudo}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Conteudo: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Conteudo
                      )}
                    </td>
                    <td>
                      {" "}
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16) // Convert to "YYYY-MM-DDTHH:MM"
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "), // Convert to "YYYY-MM-DD HH:MM:SS" for MySQL
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString() // Display in readable format
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Local || item.Local}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Local: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Local
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Contacto || item.Contacto}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Contacto: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Contacto
                      )}
                    </td>
                  </>
                )}
                {selectedResource === "aulas" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Tema || item.Tema}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Tema: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Tema
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "),
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString()
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Descricao || item.Descricao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Descricao: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Descricao
                      )}
                    </td>
                    <td>{item.ModuloId}</td>
                    <td>{item.ProfessorId}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Objectivo || item.Objectivo}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Objectivo: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Objectivo
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Duracao || item.Duracao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Duracao: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Duracao
                      )}
                    </td>
                  </>
                )}

                {selectedResource === "professores" && (
                  <>
                    <td>{item.id}</td>
                    <td>{item.UsuarioId}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Competencias || item.Competencias}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Competencias: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Competencias
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Certificacoes || item.Certificacoes}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Certificacoes: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Certificacoes
                      )}
                    </td>
                  </>
                )}

                {selectedResource === "modulos" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Nome || item.Nome}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Nome: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Nome
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Descricao || item.Descricao}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Descricao: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Descricao
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.ProfessorId || item.ProfessorId}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ProfessorId: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.ProfessorId
                      )}
                    </td>
                  </>
                )}

                {selectedResource === "consentimentos" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="checkbox"
                          checked={
                            editedData.ConsentimentoImagem ||
                            item.ConsentimentoImagem
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ConsentimentoImagem: e.target.checked,
                            })
                          }
                        />
                      ) : item.ConsentimentoImagem ? (
                        "Sim"
                      ) : (
                        "Não"
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="checkbox"
                          checked={
                            editedData.ConsentimentoEducativo ||
                            item.ConsentimentoEducativo
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ConsentimentoEducativo: e.target.checked,
                            })
                          }
                        />
                      ) : item.ConsentimentoEducativo ? (
                        "Sim"
                      ) : (
                        "Não"
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="checkbox"
                          checked={
                            editedData.ConsentimentoDados ||
                            item.ConsentimentoDados
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ConsentimentoDados: e.target.checked,
                            })
                          }
                        />
                      ) : item.ConsentimentoDados ? (
                        "Sim"
                      ) : (
                        "Não"
                      )}
                    </td>
                  </>
                )}

                {selectedResource === "balancete" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="datetime-local"
                          value={
                            editedData.DataHora
                              ? new Date(editedData.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                              : new Date(item.DataHora)
                                  .toISOString()
                                  .slice(0, 16)
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DataHora: new Date(e.target.value)
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " "),
                            })
                          }
                        />
                      ) : (
                        new Date(item.DataHora).toLocaleString()
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editedData.Montante || item.Montante}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Montante: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Montante
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={editedData.EntradaSaida || item.EntradaSaida}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              EntradaSaida: e.target.value,
                            })
                          }
                        >
                          <option value="Entrada">Entrada</option>
                          <option value="Saída">Saída</option>
                        </select>
                      ) : (
                        item.EntradaSaida
                      )}
                    </td>
                    <td>{item.FaturaId}</td>
                  </>
                )}

                {selectedResource === "alunos" && (
                  <>
                    <td>{item.id}</td>
                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Nome || item.Nome}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Nome: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Nome
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.NIF || item.NIF}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NIF: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NIF
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.NumeroUtente || item.NumeroUtente}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NumeroUtente: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NumeroUtente
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Telefone || item.Telefone}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Telefone: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Telefone
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Endereco || item.Endereco}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Endereco: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Endereco
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={
                            editedData.ContactoEmergencia ||
                            item.ContactoEmergencia 
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ContactoEmergencia: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.ContactoEmergencia
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={editedData.AulaId || item.AulaId}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              AulaId: e.target.value,
                            })
                          }
                        >
                          {/* Mapeia as aulas para criar as opções */}
                          {aulas.length > 0 ? (
                            aulas.map((aula) => (
                              <option key={aula.id} value={aula.id}>
                                {aula.Tema}
                              </option>
                            ))
                          ) : (
                            <option value="">Nenhuma aula disponível</option>
                          )}
                        </select>
                      ) : (
                        // Mostra o tema da aula selecionada
                        aulas.find((aula) => aula.id === item.AulaId)?.id ||
                        item.AulaId
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <select
                          value={
                            editedData.ConsentimentoInformado ||
                            item.ConsentimentoInformado
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ConsentimentoInformado: e.target.value,
                            })
                          }
                        >
                          <option value="Sim">Sim</option>
                          <option value="Não">Não</option>
                        </select>
                      ) : (
                        item.ConsentimentoInformado
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="checkbox"
                          checked={editedData.Presenca || item.Presenca}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Presenca: e.target.checked,
                            })
                          }
                        />
                      ) : item.Presenca ? (
                        "Sim"
                      ) : (
                        "Não"
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.NomeEncEdu || item.NomeEncEdu}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              NomeEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.NomeEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editedData.ProfissaoEncEdu || item.ProfissaoEncEdu
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ProfissaoEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.ProfissaoEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="email"
                          value={editedData.EmailEncEdu || item.EmailEncEdu}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              EmailEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.EmailEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.TelEncEdu || item.TelEncEdu}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              TelEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.TelEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editedData.EscolaridadeEncEdu ||
                            item.EscolaridadeEncEdu
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              EscolaridadeEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.EscolaridadeEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editedData.GrauParentescoEncEdu ||
                            item.GrauParentescoEncEdu
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              GrauParentescoEncEdu: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.GrauParentescoEncEdu
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editedData.Escola || item.Escola}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              Escola: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.Escola
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editedData.DoencasCronicas || item.DoencasCronicas
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              DoencasCronicas: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.DoencasCronicas
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editedData.ConhecimentoPrograma ||
                            item.ConhecimentoPrograma
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              ConhecimentoPrograma: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.ConhecimentoPrograma
                      )}
                    </td>

                    <td>
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={
                            editedData.IdParaConsentimentos ||
                            item.IdParaConsentimentos
                          }
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              IdParaConsentimentos: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.IdParaConsentimentos
                      )}
                    </td>
                  </>
                )}

                <td className="action-buttons">
                  {editItemId === item.id ? (
                    <>
                      <button
                        className="action-buttons"
                        onClick={() => handleSave(item.id)}
                      >
                        <FontAwesomeIcon icon={faSave} />{" "}
                        {/* Render icon here */}
                      </button>
                      <button
                        className="action-buttons"
                        onClick={() => handleCancel()}
                      >
                        <FontAwesomeIcon icon={faCancel} />{" "}
                        {/* Render icon here */}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="action-buttons"
                        onClick={() => handleEdit(item.id)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />{" "}
                        {/* Render icon here */}
                      </button>
                      <button
                        className="action-buttons"
                        onClick={() => handleDelete(item.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />{" "}
                        {/* Render icon here */}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* New Item Form */}
        <div className="new-item-form">
          <h2>
            Criar Novo{" "}
            {selectedResource.charAt(0).toUpperCase() +
              selectedResource.slice(1)}
          </h2>
          {selectedResource === "faturas" && (
            <>
              <input
                type="text"
                placeholder="Número de Fatura"
                value={newItem.NumeroFatura || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NumeroFatura: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="NIF"
                value={newItem.NifVendedor || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NifVendedor: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Descrição"
                value={newItem.Descricao || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Descricao: e.target.value })
                }
              />
              <input
                type="datetime-local"
                placeholder="Data e Hora"
                value={newItem.DataHora || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DataHora: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Valor"
                value={newItem.Valor || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Valor: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "usuarios" && (
            <>
              <input
                type="text"
                placeholder="Nome"
                value={newItem.Nome || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Nome: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Sobrenome"
                value={newItem.Sobrenome || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Sobrenome: e.target.value })
                }
              />
              <input
                type="number" // Changed to number type
                placeholder="NIF"
                value={newItem.NIF || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NIF: e.target.value })
                }
              />
              <input
                type="number" // Changed to number type
                placeholder="Telefone"
                value={newItem.Telefone || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Telefone: e.target.value })
                }
              />
              <input
                type="email" // Changed to email type for better validation
                placeholder="Email"
                value={newItem.Email || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Email: e.target.value })
                }
              />
              <input // Changed to textarea for address input
                type="text"
                placeholder="Endereço"
                value={newItem.Endereco || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Endereco: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Cidade"
                value={newItem.Cidade || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Cidade: e.target.value })
                }
              />
              <input
                type="number" // Changed to number type
                placeholder="NSS"
                value={newItem.NSS || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NSS: e.target.value })
                }
              />
              <select // Added a select dropdown for TipoUtilizador
                value={newItem.TipoUtilizador || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, TipoUtilizador: e.target.value })
                }
              >
                <option value="">Selecione um tipo</option>
                <option value="Administrador">Administrador</option>
                <option value="Informatico">Informático</option>
                <option value="Administrador Financeiro">
                  Administrador Financeiro
                </option>
                <option value="Criador de Conteudo">Criador de Conteúdo</option>
                <option value="Professor">Professor</option>
              </select>
              <input
                type="password" // Changed to password type for better security
                placeholder="Hashed Password"
                value={newItem.Password || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Password: e.target.value })
                }
              />
            </>
          )}

          {selectedResource === "publicacoes" && (
            <>
              <input
                type="text"
                placeholder="Título"
                value={newItem.Titulo || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Titulo: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Anexo"
                value={newItem.FotoOuVideo || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, FotoOuVideo: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Conteúdo"
                value={newItem.Conteudo || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Conteudo: e.target.value })
                }
              />
              <input
                type="datetime-local"
                placeholder="Data e Hora"
                value={newItem.DataHora || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DataHora: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Local"
                value={newItem.Local || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Local: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Contato"
                value={newItem.Contacto || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Contacto: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "aulas" && (
            <>
              <input
                type="text"
                placeholder="Tema"
                value={newItem.Tema || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Tema: e.target.value })
                }
              />
              <input
                type="datetime-local"
                placeholder="Data E Hora da Aula"
                value={newItem.DataHora || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DataHora: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Descricao"
                value={newItem.Descricao || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    Descricao: e.target.value,
                  })
                }
              />

              <input
                type="text"
                placeholder="ModuloId"
                value={newItem.ModuloId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, ModuloId: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="ProfessorId"
                value={newItem.ProfessorId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, ProfessorId: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Objectivo"
                value={newItem.Objectivo || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Objectivo: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Duração"
                value={newItem.Duracao || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Duracao: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "professores" && (
            <>
              <input
                type="text"
                placeholder="Usuário ID"
                value={newItem.UsuarioId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, UsuarioId: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Competências"
                value={newItem.Competencias || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Competencias: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Certificações"
                value={newItem.Certificacoes || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Certificacoes: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "modulos" && (
            <>
              <input
                type="text"
                placeholder="Nome"
                value={newItem.Nome || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Nome: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Descrição"
                value={newItem.Descricao || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Descricao: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Professor"
                value={newItem.ProfessorId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, ProfessorId: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "consentimentos" && (
            <>
              <input
                type="text"
                placeholder="Aluno ID"
                value={newItem.aluno_id || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, aluno_id: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Consentimento Direitos Imagem"
                value={newItem.consentimento_direitos_imagem || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    consentimento_direitos_imagem: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Consentimento Educativo"
                value={newItem.consentimento_educativo || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    consentimento_educativo: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Consentimento Uso Dados"
                value={newItem.consentimento_uso_dados || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    consentimento_uso_dados: e.target.value,
                  })
                }
              />
            </>
          )}
          {selectedResource === "relatorios" && (
            <>
              <input
                type="text"
                placeholder="Modulo"
                value={newItem.ModuloId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, ModuloId: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Numero de Participantes"
                value={newItem.NumeroParticipantes || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    NumeroParticipantes: e.target.value,
                  })
                }
              />
              <input
                type="datetime-local"
                placeholder="Data e Hora de Inicio"
                value={newItem.DataInicio || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DataInicio: e.target.value })
                }
              />
              <input
                type="datetime-local"
                placeholder="Data e Hora de Fim"
                value={newItem.DataFim || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DataFim: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Descricao"
                value={newItem.Descricao || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Descricao: e.target.value })
                }
              />
            </>
          )}
          {selectedResource === "balancete" && (
            <>
              <input
                type="datetime-local"
                placeholder="TimeStamp"
                value={
                  newItem.DataHora
                    ? new Date(newItem.DataHora).toISOString().slice(0, 16)
                    : ""
                } // Format to datetime-local
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setNewItem({ ...newItem, DataHora: date.toISOString() }); // Store as ISO string
                }}
              />
              <input
                type="text"
                placeholder="Montante"
                value={newItem.montante || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, montante: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Entrada/Saída"
                value={newItem.entrada_saida || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, entrada_saida: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Fatura"
                value={newItem.fatura || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, fatura: e.target.value })
                }
              />
            </>
          )}

          {selectedResource === "alunos" && (
            <>
              <input
                type="text"
                placeholder="Nome"
                value={newItem.Nome || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Nome: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="NIF"
                value={newItem.nif || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NIF: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Número de Utente"
                value={newItem.NumeroUtente || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NumeroUtente: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Telefone"
                value={newItem.Telefone || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Telefone: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Endereço"
                value={newItem.Endereco || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Endereco: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Contato de Emergência"
                value={newItem.ContatoEmergencia || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, ContatoEmergencia: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Aula"
                value={newItem.AulaId || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, AulaId: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Consentimento"
                value={newItem.ConsentimentoInformado || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    ConsentimentoInformado: e.target.value,
                  })
                }
              />
              <input
                type="number"
                placeholder="Presença"
                value={newItem.Presenca || 0}
                onChange={(e) =>
                  setNewItem({ ...newItem, Presenca: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Nome do Encarregado"
                value={newItem.NomeEncEdu || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, NomeEncEdu: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Profissão do Encarregado"
                value={newItem.ProfissaoEncEdu || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    ProfissaoEncEdu: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Email do Encarregado"
                value={newItem.EmailEncEdu || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, EmailEncEdu: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Telefone do Encarregado"
                value={newItem.TelEncEdu || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    TelEncEdu: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Escolaridade do Encarregado"
                value={newItem.EscolaridadeEncEdu || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    EscolaridadeEncEdu: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Grau de Parentesco"
                value={newItem.GrauParentescoEncEdu || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    GrauParentescoEncEdu: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Escola que Frequenta"
                value={newItem.Escola || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Escola: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Doenças Crónicas"
                value={newItem.DoencasCronicas || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, DoencasCronicas: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Como Conheceu"
                value={newItem.ConhecimentoPrograma || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    ConhecimentoPrograma: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Consentimentos"
                value={newItem.IdParaConsentimentos || ""}
                onChange={(e) =>
                  setNewItem({
                    ...newItem,
                    IdParaConsentimentos: e.target.value,
                  })
                }
              />
            </>
          )}
          <button onClick={handleCreate}>Criar</button>
        </div>
      </div>
    </div>
  );
};

export default Administrador;
