// src/components/Noticias.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/Noticias.css"; // Import CSS here

const Noticias = () => {
  const [news, setNews] = useState([]); // Store latest news
  const [selectedNews, setSelectedNews] = useState(null); // Store selected news
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch the 10 most recent news
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/ultimas`);
        console.log("Fetched news data:", response.data); // Log the response data
        setNews(response.data);
        setSelectedNews(response.data[0]); // Display the first news by default
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };
    fetchNews();
  }, []);

  // Show selected news and scroll
  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
    const newsElement = document.getElementById(`news-${newsItem.id}`);
    if (newsElement) {
      newsElement.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the element
    }
  };

  // Redirect to the create news form
  const handleCreateNews = () => {
    navigate("/create-news");
  };

  return (
    <div className="container">
      {/* Sidebar */}
      <div className="sidebar">
        {(userType === "Criador de Conteudo" ||
          userType === "Administrador") && (
          <button onClick={handleCreateNews} className="add-publication-btn">
            Adicionar Publicação
          </button>
        )}

        <h2>Últimas Notícias</h2>
        <ul>
          {news.map((newsItem) => (
            <li key={newsItem.id}>
              <button
                onClick={() => handleNewsClick(newsItem)}
                className="news-button"
              >
                {newsItem.Titulo}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* News cards in the center of the page */}
      <div className="main-content">
        <div className="news-container">
          {news.map((newsItem) => (
            <div
              className="news-card"
              key={newsItem.id}
              id={`news-${newsItem.id}`}
            >
              <h1>{newsItem.Titulo}</h1>
              {/* Render image or video */}
              {newsItem.FotoOuVideo && (
                <img
                src={`./assets/uploads/${newsItem.FotoOuVideo}`} // Corrected URL
                alt="Mídia da Notícia"
                className="news-image"
              />
              
              )}
              {/* Render formatted content */}
              <div className="conteudo">
                <div dangerouslySetInnerHTML={{ __html: newsItem.Conteudo }} />
                <p>
                  <strong>Data e Hora:</strong>{" "}
                  {new Date(newsItem.DataHora).toLocaleString()}
                </p>
                <p>
                  <strong>Local:</strong> {newsItem.Local}
                </p>
                <p>
                  <strong>Contacto:</strong> {newsItem.Contacto}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Noticias;
