import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/Professor.css";

const Professor = () => {
  const [aulas, setAulas] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [activeModuleId, setActiveModuleId] = useState(null);
  const [filteredAulas, setFilteredAulas] = useState([]);
  const [activeModule, setActiveModule] = useState(null);
  const [moduloId, setModuloId] = useState(null); // Define the moduloId state
  const UsuarioId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [professor, setProfessor] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ;
  useEffect(() => {
    if (UsuarioId) {
      axios
        .get(`${API_BASE_URL}/professorusuario/${UsuarioId}`)
        .then((res) => {
          const fetchedData = res.data;
          setProfessor(fetchedData);
        })
        .catch((err) => console.error("Erro ao buscar Professor", err));
    }
  }, [UsuarioId]);

  useEffect(() => {
    if (professor.length > 0 && professor[0].id) {
      const fetchModulos = async () => {
        try {
          const res = await axios.get(
            `${API_BASE_URL}/modulos/professor/${professor[0].id}`
          );
          setModulos(res.data);
        } catch (err) {
          console.error("Erro ao buscar módulos", err);
        }
      };
      fetchModulos();
    }
  }, [professor]);

  useEffect(() => {
    if (professor.length > 0 && professor[0].id) {
      axios
        .get(`${API_BASE_URL}/professor/aulas/${professor[0].id}`)
        .then((res) => setAulas(res.data))
        .catch((err) => console.error("Erro ao buscar aulas", err));
    }
  }, [professor]);

  useEffect(() => {
    if (activeModuleId) {
      const fetchAulasByModulo = async () => {
        try {
          const res = await axios.get(
            `${API_BASE_URL}/aulas/modulo/${activeModuleId}`
          );
          setFilteredAulas(res.data);
        } catch (err) {
          console.error("Erro ao buscar aulas do módulo", err);
        }
      };
      fetchAulasByModulo();

      // Fetch the active module details
      const fetchActiveModule = async () => {
        try {
          const res = await axios.get(`${API_BASE_URL}/modulos/${activeModuleId}`);
          setActiveModule(res.data);
        } catch (err) {
          console.error("Erro ao buscar módulo ativo", err);
        }
      };
      fetchActiveModule();
    } else {
      setFilteredAulas([]);
      setActiveModule(null);
    }
  }, [activeModuleId]);

  const goToCreateAula = (moduloId) => {
    navigate("/create-aula", {
      state: { moduloId, professorId: professor[0].id },
    });
  };

  const goToEditAula = (aulaId, moduloId) => {
    navigate(`/edit-aula/`, {
      state: { aulaId, moduloId, professorId: professor[0].id },
    });
  };

  const goToCreateModulo = () => {
    navigate("/create-modulo", { state: { professorId: professor[0].id } });
  };

  const goToEditModulo = () => {
    navigate("/create-modulo", { state: { moduloId: activeModuleId, professorId: professor[0].id } }); // Use activeModuleId here
  };

  const viewStudents = (aulaId) => {
    navigate(`/alunos/${aulaId}`);
  };

  return (
    <div className="professor-container">
      <div className="professor-topbar">
        <button className="professor-create-module" onClick={goToCreateModulo}>
          Criar Módulo
        </button>
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div className="professor-sidebar">
          <h2>Módulos</h2>
          <ul className="professor-list">
            {modulos.map((modulo) => (
              <li key={modulo.id} className="professor-list-item">
                <button onClick={() => {
                  setActiveModuleId(modulo.id);
                  setModuloId(modulo.id); // Set moduloId when a module is selected
                }}>
                  {modulo.Nome}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="professor-center">
          {activeModule && <h2>Descrição do Módulo:</h2>}
          {activeModule && <div dangerouslySetInnerHTML={{ __html: activeModule.Descricao }} />}
          {activeModule && <button onClick={goToEditModulo}>Editar Modulo</button>}
          {activeModule && <h2>Aulas</h2>}
          {activeModuleId && (
            <button onClick={() => goToCreateAula(activeModuleId)}>
              Criar Aula
            </button>
          )}
          <ul className="professor-list">
            {filteredAulas.map((aula) => (
              <li key={aula.id} className="professor-list-item">
                {aula.Tema} - {new Date(aula.DataHora).toISOString().slice(0, 16).replace("T", " ")}
                <h3>Descricao</h3>
                {activeModule && <div dangerouslySetInnerHTML={{ __html: aula.Descricao }} />}
                <h3>Objectivo:</h3>
                {activeModule && <div dangerouslySetInnerHTML={{ __html: aula.Objectivo }} />}
                <button onClick={() => viewStudents(aula.id)}>
                  Ver Alunos
                </button>
                <button onClick={() => goToEditAula(aula.id, activeModuleId)}>
                  Editar Aula
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Professor;
