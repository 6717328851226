import React from "react";
import "../styles/Donate.css"; // Adicione seus estilos aqui

const Donate = () => {
  return (
    <div className="backgroundin">
      <div className="donate-container">
        <h1>Doe para a OPAH</h1>
        <div className="piggy-bank-icon">
          {/* SVG do porquinho */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="100"
            height="100"
          >
            <path
              fill="#FFB74D"
              d="M32 4a28 28 0 0 0-28 28c0 12.74 8.28 23.56 19.63 27.35 2.05 1.32 4.37 1.65 6.76 1.65s4.71-.33 6.76-1.65C51.72 55.56 60 44.74 60 32A28 28 0 0 0 32 4zm0 50a20 20 0 1 1 20-20 20.07 20.07 0 0 1-20 20zm8-26H24v4h16zm0 8H24v4h16z"
            />
          </svg>
        </div>
        <p>Aqui estão as informações bancárias da nossa associação:</p>
        <ul>
          <li>Nome : OPAH ORGANIZAÇÃO PORTUGUESA DE AJUDA HUMANITÁRIA</li>
          <li>NPIC : 517 245 329</li>
          <li>Banco : BPI </li>
          <li>IBAN : PT50 0010 0000 6131 1790 00122</li>
        </ul>
        <p>
          Após realizar a transferência, por favor envie o comprovativo para
          <a href="mailto:geral@opah.pt"> geral@opah.pt</a>.
        </p>
        <button onClick={() => (window.location.href = "mailto:geral@opah.pt")}>
          Enviar Comprovativo
        </button>
      </div>
    </div>
  );
};

export default Donate;
