// src/components/Footer.js
import React from "react";
import "../styles/Footer.css"; // Make sure to create this CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFileAlt, faMapLocation } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column social-buttons">
          <div className="social-icons">
            <a
              href="https://www.facebook.com"
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} className="link-icon" />
            </a>
            <a
              href="https://www.instagram.com/associacao.opah"
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="link-icon" />
            </a>
            <a
              href="https://wa.me/+351911193330"
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} className="link-icon" />
            </a>
          </div>
        </div>
        <div className="footer-column">
          <a
            href="https://maps.app.goo.gl/QtPvUbwAojnRudwy7"
            className="social-link"
          >
            <FontAwesomeIcon icon={faMapLocation} className="link-icon" />
          </a>
        </div>
        <div className="footer-column">
          <a
            href="https://www.gnu.org/licenses/licenses.pt-br.html"
            className="social-link"
          >
            <FontAwesomeIcon icon={faFileAlt} className="link-icon" />
          </a>
        </div>
      </div>
        © 2024 Organização Portuguesa de Ajuda Humanitária. Todos os direitos
        reservados.
        <a href="mailto:porqueseretico@gmail.com" className="social-link">
          Contate o Dev
        </a>
    </footer>
  );
};

export default Footer;
