import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Ensure it's imported correctly
import logo from "../assets/logo.png"; // Adjust logo path
import "../styles/Navbar.css"; // Import CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faNewspaper,
  faInfoCircle,
  faBook,
  faSignInAlt,
  faSignOutAlt,
  faHandshake,
  faTicket,
  faBars, // Hamburger menu icon
} from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome icons

const Navbar = () => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth(); // Get setter for isLoggedIn from context
  const [isLoggedIn, setLocalIsLoggedIn] = useState(false); // Local isLoggedIn state
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu open/close
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  let autoCollapseTimer; // Variable to hold the auto collapse timer

  // Check login status on component mount
  useEffect(() => {
    const checkLoginStatus = () => {
      const loggedIn = localStorage.getItem("isLoggedIn") === "true";
      setLocalIsLoggedIn(loggedIn); // Update local state
      setIsLoggedIn(loggedIn); // Update context state
    };

    checkLoginStatus(); // Check login status on component load

    const handleStorageChange = () => {
      checkLoginStatus(); // Re-check login status when localStorage changes
    };

    window.addEventListener("storage", handleStorageChange); // Listen for changes to localStorage

    return () => {
      window.removeEventListener("storage", handleStorageChange); // Remove listener on component unmount
      clearTimeout(autoCollapseTimer); // Clear timer on unmount
    };
  }, [setIsLoggedIn]); // Update when setIsLoggedIn changes

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove token from localStorage
    localStorage.setItem("isLoggedIn", "false"); // Update localStorage for logout
    setLocalIsLoggedIn(false); // Update local state
    setIsLoggedIn(false); // Update context state
    localStorage.removeItem("userType");
    localStorage.removeItem("userId");
    navigate("/"); // Redirect to home or login page
  };

  // Function to navigate to login
  const handleLoginClick = () => {
    navigate("/login"); // Redirect to login page
    window.location.reload(); // Reload the page
  };

  // Function to toggle the hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state

    // Clear existing timer if any
    clearTimeout(autoCollapseTimer);

    if (!isMenuOpen) {
      // If opening the menu, set a timer to auto-collapse
      autoCollapseTimer = setTimeout(() => {
        setIsMenuOpen(false); // Close the menu after 5 seconds
      }, 5000); // Adjust duration as needed
    }
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Logo" className="logo" />
      <div className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} /> {/* Hamburger menu icon */}
      </div>
      <ul className={`nav-list ${isMenuOpen ? 'active' : ''}`}>
        <li>
          <Link to="/" className="nav-link" title="Página Inicial">
            <FontAwesomeIcon icon={faHome} />{" "}
          </Link>
        </li>
        <li>
          <Link to="/noticias" className="nav-link" title="Noticias">
            <FontAwesomeIcon icon={faNewspaper} />{" "}
          </Link>
        </li>
        <li>
          <Link to="/sobre" className="nav-link" title="Sobre">
            <FontAwesomeIcon icon={faInfoCircle} />{" "}
          </Link>
        </li>
        <li>
          <Link to="/aulas" className="nav-link" title="Aulas">
            <FontAwesomeIcon icon={faBook} /> Aulas{" "}
          </Link>
        </li>
        <li>
          <Link to="/parcerias" className="nav-link" title="Parcerias">
            <FontAwesomeIcon icon={faHandshake} /> Parcerias{" "}
          </Link>
        </li>
        
      </ul>
      <ul className="nav-list-2">
      {isLoggedIn ? (
          <li>
            <Link onClick={handleLogout} className="nav-link">
              <FontAwesomeIcon icon={faSignOutAlt} />{" "}
            </Link>
          </li>
        ) : (
          <li>
            <Link onClick={handleLoginClick} className="nav-link">
              <FontAwesomeIcon icon={faSignInAlt} />{" "}
            </Link>
          </li>
        )}
        <li>
          <a
            href="mailto:porqueseretico@gmail.com"
            className="nav-link"
            title="Obtenha Suporte"
          >
            <FontAwesomeIcon icon={faTicket} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
